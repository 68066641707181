@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons);
/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600'); */

#landing-header {
  z-index: 1;
  position: relative;
  text-align: center;
  padding-top: 35vh;
}

#landing-header h1 {
  color: #fff;
  font-family: 'Roboto', 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 54px;
  margin-bottom: 20px;
}

.slideshow {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

.slideshow li {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  animation: imageAnimation 50s linear infinite;
}

.slideshow li:nth-child(1) {
  background-image: url(/static/media/01.3c9a7547.jpg);
}
.slideshow li:nth-child(2) {
  background-image: url(/static/media/02.67be9a1a.jpg);
  animation-delay: 10s;
}
.slideshow li:nth-child(3) {
  background-image: url(/static/media/03.3f52abde.jpg);
  animation-delay: 20s;
}
.slideshow li:nth-child(4) {
  background-image: url(/static/media/06.897b2757.jpg);
  animation-delay: 30s;
}
.slideshow li:nth-child(5) {
  background-image: url(/static/media/05.ad2175b6.jpg);
  animation-delay: 40s;
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  10% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
}

/*
Reference:
http://www.bootstrapzen.com/item/135/simple-login-form-logo/
*/

* {
  box-sizing: border-box;
  outline: none;
}

.form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  @include box-sizing(border-box);

  &:focus {
    z-index: 2;
  }
}

.wrapper {
  background: url(https://i.imgur.com/GHr12sH.jpg) no-repeat center center fixed;
  background-size: cover;
  height: 120vh;
}

.login-form {
  margin: 130px auto;
}

form[role='login'] {
  color: #5d5d5d;
  background: #f2f2f2;
  padding: 26px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
form[role='login'] img {
  display: block;
  margin: 0 auto;
  margin-bottom: 35px;
}
form[role='login'] input,
form[role='login'] button {
  font-size: 18px;
  margin: 16px 0;
}
form[role='login'] > div {
  text-align: center;
}

.form-links {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 50px;
}
.form-links a {
  color: #fff;
}

button {
  cursor: pointer;
}

.spinner {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: 50px;
  background-size: 100%;
}

#myFooter {
  color: #777;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
}

#myFooter .footer-copyright {
  background-color: #333333;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}

#myFooter .row {
  margin-bottom: 10px;
}

#myFooter .navbar-brand {
  margin-top: 20px;
  height: 30px;
}

#myFooter .footer-copyright p {
  margin: 10px;
  color: #ccc;
}

#myFooter ul {
  list-style-type: none;
  padding-left: 0;
  line-height: 1.7;
}

#myFooter h5 {
  font-size: 18px;
  color: #777;
  font-weight: bold;
  margin-top: 30px;
}

#myFooter h2 a {
  font-size: 50px;
  text-align: center;
  color: #fff;
}

#myFooter a {
  color: #777;
  text-decoration: none;
}

#myFooter a:hover,
#myFooter a:focus {
  text-decoration: none;
  color: white;
}

#myFooter .social-networks {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 16px;
}

#myFooter .social-networks a {
  font-size: 32px;
  color: #777;
  padding: 10px;
  transition: 0.2s;
}

#myFooter .social-networks a:hover {
  text-decoration: none;
}

#myFooter .facebook:hover {
  color: #0077e2;
}

#myFooter .google:hover {
  color: #ef1a1a;
}

#myFooter .twitter:hover {
  color: #00aced;
}

#myFooter .btn {
  color: white;
  background-color: #d84b6b;
  border-radius: 20px;
  border: none;
  width: 150px;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  line-height: 25px;
}

@media screen and (max-width: 767px) {
  #myFooter {
    text-align: center;
  }
}

.navbar {
  padding: 10px 0;
}

a.navbar-brand {
  padding: 0 15px;
}

.upload_container__K6R1g {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.upload_containerItem__2S6bK {
  display: flex;
  /* margin-right: 16px; */
  margin-bottom: 16px;
}

.upload_placeholder__326tz {
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  display: flex;
  width: 100%;
  font-size: 14px;
}


@media (max-width: 767px) {
  .pull-right {
    text-align: left;
  }
}

@media (min-width: 500px) {
  .button-lineup {
    padding-right: 78px;
  }
}

.booking-main-header {
  margin-bottom: 50px;
}

/* @media (max-width: 766px) {
  .button-lineup {
    padding-right: 0;
  }
} */

fieldset.for-panel {
  background-color: #fcfcfc;
  border: 1px solid #999;
  border-radius: 4px;
  border-color: #bce8f1;
  padding: 20px 10px;
  background-color: #d9edf7;
  background-color: #f9fdfd;
  margin-bottom: 12px;
}
fieldset.for-panel legend {
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #4381ba;
  font-size: 14px;
  font-weight: bold;
  line-height: 10px;
  margin: inherit;
  padding: 7px;
  width: auto;
  background-color: #d9edf7;
  margin-bottom: 0;
}

.thumbnail {
  padding: 0px;
}
.panel {
  position: relative;
}
.panel > .panel-heading:after,
.panel > .panel-heading:before {
  position: absolute;
  top: 11px;
  left: -16px;
  right: 100%;
  width: 0;
  height: 0;
  display: block;
  content: ' ';
  border-color: transparent;
  border-style: solid solid outset;
  pointer-events: none;
}
.panel > .panel-heading:after {
  border-width: 7px;
  border-right-color: #f7f7f7;
  margin-top: 1px;
  margin-left: 2px;
}
.panel > .panel-heading:before {
  border-right-color: #ddd;
  border-width: 8px;
}

a:link,
a:visited {
  text-decoration: none;
  display: inline-block;
}

a:hover {
  cursor: pointer;
}

.btn-booking {
  margin-right: 3px;
}

.container-bottom {
  margin-bottom: 500px;
  margin-top: 100px;
}

hr {
  margin: 10px 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

.add-detail {
  margin-top: 20px;
  margin-left: 15px;
}

.add-detail-margin {
  margin-bottom: 20px;
}

table input.form-control {
  text-align: right;
}

.detail-submenu {
  margin-bottom: 20px;
  margin-top: -30px;
}

i.anticon.anticon-right.arrow {
  margin-left: 0;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
  background-color: #f9fdfd;
}

.move.btn.btn-sm.btn-default {
  margin-left: 15px;
}

.custom-modal {
  width: 80%;
}
.custom-air-modal {
  width: 50%;
}

button.add-detail.btn.btn-sm.btn-default {
  margin-top: 20px;
  margin-left: 15px;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

@media (max-width: 767px) {
  .pie-chart {
    margin-left: -40px;
    padding: 0;
  }
}

/* .pie-chart {
  margin-left: -100px;
} */



.csv-btn {
  margin-bottom: 15px;
}

/* .container-margin {
  margin-top: 150px;
  margin-bottom: 200px;
} */

.container {
  margin: 5px auto;
  margin-bottom: 76px;
  padding-bottom: 32px;
}

/* .container-margin {
  margin-top: 100px;
  margin-bottom: 510px;
} */

h2 {
  margin: 50px auto;
}

.submenu {
  width: 100%;
  margin: 20px auto;
  padding-left: 10px;
}

.col-sm-10 .col-sm-offset-1 > .container-margin-sendmail {
  margin-top: 300px;
}

textarea {
  overflow: auto;
}

i {
  cursor: pointer;
  margin-left: 15px;
}

@media (min-width: 768px) {
  .col-sm-4 {
    float: left;
  }
  .col-sm-4 {
    width: 25.16666667%;
  }
}

label .col-sm-2 {
  padding: 0;
}

